import * as React from "react"
import styled from "styled-components"

const StyledMain = styled.footer`
  padding: 1rem;
`

const Main = ({ children }) => <StyledMain>{children}</StyledMain>

export default Main
