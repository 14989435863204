import * as React from "react"
import styled from "styled-components"

const StyledFooter = styled.footer`
  margin-top: 1rem;
  padding: 1rem;
`

const Footer = () => (
  <StyledFooter>&copy; {new Date().getFullYear()}</StyledFooter>
)

export default Footer
